import { Checkbox } from "@mui/material";
import { makeStyles } from "@mui/styles";

import React, { useState } from "react";
// const useStyles = makeStyles({
//   root: {
//     color: theme.palette.common.white,
//     '&$checked': {
//       color: 'red',
//     },
//   },
//   checked: {},
// });

const ReusableTable = ({
  suggestedArray,
  matchingArray,
  handleSendApiSingleSuggested,
  selectedIndices,
  setSelectedIndices,
  count,
  array,
  setLoader,
}) => {
  console.log("matchingArray", count);

  const handleMatching = (val, index) => {
    setSelectingMatching((prevSelectedIndices) => [
      ...prevSelectedIndices,
      index,
    ]);
    handleSendApiSingleSuggested(val);
    console.log("handleMatching", val);
  };
  const [selectMatching, setSelectingMatching] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedQuickInsert, setselectedQuickInsert] = useState([]);
  const [selectAllQuick, setSelectedAllQuick] = useState(false);

  const [selectAll, setSelectAll] = useState(false);
  const [messageShow, setMessageShow] = useState({
    updateMessage: "",
    quickInsert: "",
  });

  console.log("selectedRows", selectedQuickInsert);
  const handleCheckboxChange = (event, val) => {




    const isChecked = event.target.checked;
    if (val === "all") {
      setSelectAll(isChecked);
      if (isChecked) {
        setSelectedRows([...matchingArray]);
      } else {
        setSelectedRows([]);
      }
    } else {
      if (isChecked) {
        setSelectedRows([...selectedRows, val]);
      } else {
        setSelectedRows(selectedRows.filter((row) => row !== val));
      }
    }
  };
  const handleCheckboxQuick = (event, val) => {
    const isChecked = event.target.checked;
    if (val === "all") {
      setSelectedAllQuick(isChecked);
      if (isChecked) {
        setselectedQuickInsert([...suggestedArray]);
      } else {
        setselectedQuickInsert([]);
      }
    } else {
      if (isChecked) {
        setselectedQuickInsert([...selectedQuickInsert, val]);
      } else {
        setselectedQuickInsert(
          selectedQuickInsert.filter((row) => row !== val)
        );
      }
    }
  };
  const handleQuickInsert = async () => {
    let demyArray = [];
    setLoader(true);

    for (let index = 0; index < selectedQuickInsert.length; index++) {
      const element = selectedQuickInsert[index];
      console.log("valueArray", element);
      demyArray[index] = {
        price: element?.price,
        name: element?.name,
        sku: element?.sku,
        global_sku: "",
        catalog_id: array?.param4,
      };
    }
    console.log("demyArray", demyArray);
    let payload = {
      lang: "en",
      data: demyArray,
    };

    //   console.log("valueArray", selectedRows);
    //  const response = await fetch(
    //   `https://www.vconnectlive.com/apiv2/ocr/products/quickupdate.jsp`,
    //   {
    //     method: "POST",
    //     body: JSON.stringify(payload),
    //     headers: {
    //       "Content-Type": "application/x-www-form-urlencoded",
    //       "api-key": "8f32a2d8-a84c-11eb-aa3f-ac1f6bb5693e",
    //       "auth-token": "0c4e13fa-6c30-4d88-b560-6498ef2a8b0e",
    //       "site-uid": "fc4dcd1d-0b16-11ec-aa3f-ac1f6bb5693e",
    //     },
    //   }
    // );

    const response = await fetch(
      `${array?.param3}/apiv2/ocr/products/quickinsert.jsp`,
      {
        method: "POST",
        body: JSON.stringify(payload),
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "api-key": "8f32a2d8-a84c-11eb-aa3f-ac1f6bb5693e",
          "auth-token": array?.param1,
          "site-uid": array?.param2,
        },
      }
    );
    const result = await response.json();
    console.log("selectedrowresult", result);
    setMessageShow({ quickInsert: result?.msg });
    setselectedQuickInsert([]);
    setSelectedAllQuick(false);
    setLoader(false);

    const timeoutId = setTimeout(() => {
      setMessageShow({ updateMessage: "", quickInsert: "" });
    }, 2000);

    // Cleanup function to clear the timeout when component unmounts or when dependency array changes
    return () => clearTimeout(timeoutId);
  };
  const handleQuickUpdate = async () => {
    let demyArray = [];
    setLoader(true);

    for (let index = 0; index < selectedRows.length; index++) {
      const element = selectedRows[index];
      console.log("valueArray", element);
      demyArray[index] = {
        product_id: element?.product?.product_id,
        price: element?.price,
        variant_id: element?.product?.variant_id,
        ...(element?.product?.variation_id && {
          variation_id: element?.product?.variation_id,
        }),
      };
    }
    console.log("demyArray", demyArray);
    let payload = {
      lang: "en",
      data: demyArray,
    };

    //   console.log("valueArray", selectedRows);
    //  const response = await fetch(
    //   `https://www.vconnectlive.com/apiv2/ocr/products/quickupdate.jsp`,
    //   {
    //     method: "POST",
    //     body: JSON.stringify(payload),
    //     headers: {
    //       "Content-Type": "application/x-www-form-urlencoded",
    //       "api-key": "8f32a2d8-a84c-11eb-aa3f-ac1f6bb5693e",
    //       "auth-token": "0c4e13fa-6c30-4d88-b560-6498ef2a8b0e",
    //       "site-uid": "fc4dcd1d-0b16-11ec-aa3f-ac1f6bb5693e",
    //     },
    //   }
    // );

    const response = await fetch(
      `${array?.param3}/apiv2/ocr/products/quickupdate.jsp`,
      {
        method: "POST",
        body: JSON.stringify(payload),
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "api-key": "8f32a2d8-a84c-11eb-aa3f-ac1f6bb5693e",
          "auth-token": array?.param1,
          "site-uid": array?.param2,
        },
      }
    );
    const result = await response.json();
    console.log("selectedrowresult", result);
    setMessageShow({ updateMessage: result?.msg });
    setSelectedRows([]);
    setSelectAll(false);
    setLoader(false);

    const timeoutId = setTimeout(() => {
      setMessageShow({ updateMessage: "", quickInsert: "" });
    }, 2000);

    // Cleanup function to clear the timeout when component unmounts or when dependency array changes
    return () => clearTimeout(timeoutId);
  };
  const handleNext = (val, index) => {
    console.log("newData", val);
    handleSendApiSingleSuggested(val, index);
    //setCurrentIndex(nextIndex);
    setSelectedIndices((prevSelectedIndices) => [
      ...prevSelectedIndices,
      index,
    ]);
  };
  const [displayMatching, setDisplayMatching] = useState(false); // State to toggle between displaying matching and suggested items

  const handleDisplayChange = (displayMatching) => {
    setDisplayMatching(displayMatching); // Function to handle the change in display
  };
  console.log("dispalyMatching", displayMatching);

  return (
    <>
      <div className="newUiButtonsLayout">
        <button
          className="newUiButtons"
          style={{
            backgroundColor: displayMatching ? "#fff" : "",
            color: displayMatching ? "#86919D" : "",
          }}
          onClick={() => handleDisplayChange(false)}
        >
          New Products{" "}
        </button>{" "}
        <button
          className="newUiButtons"
          style={{
            backgroundColor: !displayMatching ? "#fff" : "",
            color: !displayMatching ? "#86919D" : "",
          }}
          onClick={() => handleDisplayChange(true)}
        >
          Existing Products
        </button>{" "}
        {/* <input
          type="radio"
          id="displaySuggested"
          name="displayOption"
          value={false}
          checked={!displayMatching}
          onChange={() => handleDisplayChange(false)}
        />
        <label htmlFor="displaySuggested">New Products</label>
        <input
          style={{ marginLeft: "15px" }}
          type="radio"
          id="displayMatching"
          name="displayOption"
          value={true}
          checked={displayMatching}
          onChange={() => handleDisplayChange(true)}
        />
        <label htmlFor="displayMatching">Existing Products</label> */}
      </div>
      <>
        {displayMatching ? (
          <div
            style={{
              textAlign: "center",
              color: "#747474",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItem: "center",
              }}
            >
              <p className="headingNextNewUi" style={{ textAlign: "start" }}>
                Matching{" "}
              </p>
              {matchingArray?.length > 0 && (
                <p className="form-control">
                  <input
                    style={{ height: "22px", width: "22px" }}
                    type="checkbox"
                    checked={selectAll}
                    onChange={(event) => handleCheckboxChange(event, "all")}
                  />{" "}
                  <span
                    style={{
                      color: "#747474",
                      fontSize: "16px",
                      marginTop: "-1px",
                    }}
                  >
                    Select All
                  </span>
                </p>
              )}
            </div>
            <div
              style={{
                fontSize: "15px",
                fontWeight: "500",
                textAlign: "start",
                lineHeight: "20px",
              }}
            >
              Click the Product to update
            </div>
            <>
              {matchingArray?.length > 0 ? (
                <>
                  <div
                    style={{
                      width: "100%",
                      maxHeight: "60vh",
                      overflow: "auto",
                    }}
                  >
                    <div>
                      {matchingArray?.map((val, index) => (
                        <div
                          style={{
                            background: selectMatching.includes(index)
                              ? "#EFF1FF"
                              : "",
                            cursor: selectMatching.includes(index)
                              ? "pointer"
                              : "default",
                            boxShadow: selectMatching.includes(index)
                              ? "0px 0px 20px 0px #0000001A"
                              : "",
                            // Change cursor style based on selection
                            // pointerEvents: selectMatching.includes(index)
                            //   ? "none"
                            //   : "auto",
                          }}
                          className="row-new-ui"
                          onTouchStart={(event) =>
                                event.target.classList.add("touched")
                              }
                              onTouchEnd={(event) =>
                                event.target.classList.remove("touched")
                              }
                              onClick={(e) =>{ handleMatching(val, index)}}
                        >
                          <div className="row-all">
                            <div
                              // style={{ textDecoration: "underline" }}
                              className="row-name"
                             
                            >
                              {val?.name}
                            </div>
                            <div
                              style={{
                                display: "flex",
                                gap: "13px",
                                alignItems: "center",
                              }}
                            >
                              <div
                                className="row-price"
                                
                              >
                                Price:{val?.price}
                              </div>
                              <div
                                className="row-sku"
                            
                              >
                                SKU:{val?.sku}
                              </div>
                            </div>
                          </div>

                          <div
                            class="form-control"
                            style={{ paddingRight: "30px" }}
                          >
                            <input
                              type="checkbox"
                              checked={selectAll || selectedRows.includes(val)}
                              onChange={(event) =>{
                                handleCheckboxChange(event, val)}
                              }
                              onClick={(event) => event.stopPropagation()} // Stop propagation for checkbox clicks

                            />
                          </div>
                        </div>
                      ))}

                      {/* ))} */}
                    </div>
                  </div>
                  <div>
                    <div>
                      <button
                        className="all-buttons"
                        style={{
                          backgroundColor:
                            selectedRows.length > 0
                              ? "rgb(47, 63, 125)"
                              : "rgba(47, 63, 125, 0.5)",
                          cursor:
                            selectedRows.length > 0 ? "pointer" : "not-allowed",
                        }}
                        disabled={selectedRows.length > 0 ? false : true}
                        onClick={handleQuickUpdate}
                        aria-hidden="true"
                      >
                        Update
                      </button>
                    </div>
                    {messageShow?.updateMessage && (
                      <p
                        style={{ fontSize: "15px" }}
                        className="headingNextNewUi"
                      >
                        {messageShow?.updateMessage}
                      </p>
                    )}
                    <div style={{ textAlign: "center", color: "#747474" }}>
                      <p>This will update prices of above products.</p>
                    </div>
                  </div>
                </>
              ) : (
                <p>No Matching Found</p>
              )}
            </>
          </div>
        ) : (
          <div
            style={{
              textAlign: "center",
              color: "#747474",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItem: "center",
              }}
            >
              <p className="headingNextNewUi" style={{ textAlign: "start" }}>
                New Products
              </p>
              {suggestedArray?.length > 0 && (
                <p className="form-control">
                  <input
                    style={{ height: "22px", width: "22px" }}
                    type="checkbox"
                    checked={selectAllQuick}
                    onChange={(event) => handleCheckboxQuick(event, "all")}
                  />{" "}
                  <span
                    style={{
                      color: "#747474",
                      fontSize: "16px",
                      marginTop: "-1px",
                    }}
                  >
                    Select All
                  </span>
                </p>
              )}
            </div>
            {suggestedArray?.length > 0 ? (
              <>
                <div
                  style={{ width: "100%", maxHeight: "60vh", overflow: "auto" }}
                >
                  {suggestedArray?.map((val, index) => (
                    <div
                      style={{
                        background: selectedIndices.includes(index)
                          ? "#EFF1FF"
                          : "",
                        cursor: selectedIndices.includes(index)
                          ? "pointer"
                          : "default",
                        boxShadow: selectedIndices.includes(index)
                          ? "0px 0px 20px 0px #0000001A"
                          : "",
                        // Change cursor style based on selection
                        // pointerEvents: selectMatching.includes(index)
                        //   ? "none"
                        //   : "auto",
                      }}
                      onTouchStart={(event) =>
                        event.target.classList.add("touched")
                      }
                      onTouchEnd={(event) =>
                        event.target.classList.remove("touched")
                      }
                      onClick={() => {
                        handleNext(val, index);
                      }}
                      className="row-new-ui"
                    >
                      <div className="row-all">
                        <div className="row-name">{val?.name}</div>
                        <div
                          style={{
                            display: "flex",
                            gap: "13px",
                            alignItems: "center",
                          }}
                        >
                          <div className="row-price">Price:{val?.price}</div>
                          <div className="row-sku">SKU: {val?.sku}</div>
                        </div>
                      </div>

                      <div
                        class="form-control"
                        style={{ paddingRight: "30px" }}
                      >
                        <input
                          type="checkbox"
                          checked={
                            selectAllQuick || selectedQuickInsert.includes(val)
                          }
                          onChange={(event) => handleCheckboxQuick(event, val)}
                          onClick={(event) => event.stopPropagation()} // Stop propagation for checkbox clicks

                        />
                      </div>
                    </div>
                  ))}
                </div>
                <div>
                  <button
                    className="all-buttons"
                    style={{
                      backgroundColor:
                        selectedQuickInsert.length > 0
                          ? "rgb(47, 63, 125)"
                          : "rgba(47, 63, 125, 0.5)",
                      cursor:
                        selectedQuickInsert.length > 0
                          ? "pointer"
                          : "not-allowed",
                    }}
                    disabled={selectedQuickInsert.length > 0 ? false : true}
                    onClick={handleQuickInsert}
                    aria-hidden="true"
                  >
                    Quick Insert
                  </button>
                </div>
                {messageShow?.quickInsert && (
                  <p style={{ fontSize: "15px" }} className="headingNextNewUi">
                    {messageShow?.quickInsert}
                  </p>
                )}
              </>
            ) : (
              <p>No Suggested Found</p>
            )}
          </div>
        )}
      </>
    </>
  );
};

export default ReusableTable;
