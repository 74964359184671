import React, { useState, useRef, useEffect } from "react";

import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop";
import cropImage from "./images/crop.png";
import "react-image-crop/dist/ReactCrop.css";
import english from "./images/svg/english.svg"
import port from "./images/svg/portuges.svg"
import french from "./images/svg/french.svg"
import russ from "./images/svg/russia.svg"
import arab from "./images/svg/arabic.svg"
import span from "./images/svg/spanish.svg"




import { canvasPreview } from "./canvasPreview";
import { useDebounceEffect } from "./useDebounceEffect";
import image from "./images/svg/imageselect.svg";
import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
// This is to demonstate how to make and center a % aspect crop
// which is a bit trickier so we use some helper functions.
function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: 90,
      },
      aspect,
      
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}

export default function ImageCrop({
  setImageData,
  setShowCase,
  showCase,
  language,
  setImgSrc,
  dispaly,
  setDisplay,
  setOnlySeeImage,
  onlySeeImage,
  setArray,
  imgSrc,
  handleButtonClick,
  fileInputRef,
  setOcr,
  setLanguage,
  previewCanvasRef,
  handleResetStates,
  imgRef,
  hiddenAnchorRef,
  blobUrlRef,
  crop,
  setCrop,
  completedCrop,
  setCompletedCrop,
  aspect,
  setAspect,
}) {
  const [scale, setScale] = useState(1);
  // const [handleToggle, setHandleToggle] = useState(false);
  const [rotate, setRotate] = useState(0);

  function onSelectFile(e) {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setOnlySeeImage(reader.result?.toString() || "");
        setImgSrc(reader.result?.toString() || "");
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  }

  // }, [onlySeeImage]);
  useEffect(() => {
    if (onlySeeImage) {
      setShowCase(false);
      setTimeout(() => {
        setAspect(undefined);
      }, 3000);
    }
  }, [onlySeeImage, setAspect]);

  useEffect(() => {
    window.handleCallback = handleCallback;

    // Clean up the handler when the component unmounts
    return () => {
      delete window.handleCallback;
    };
  }, []);
  const handleCallback = (param1, param2, param3,param4) => {
    // Do something with the received data
    console.log("paramsall",param1, param2, param3,param4)
    setArray({ param1, param2, param3,param4 });
  };

  const handleCheck = () => {
    // For iOS
    if (window.webkit && window.webkit.messageHandlers.receivedData) {
      // Call the receivedData function and capture the returned values
      window.webkit.messageHandlers.receivedData.postMessage(
        JSON.stringify({ callback: "handleCallback" })
      );

      // Check if the result is an array with two values
    }

    // For Android
    if (window.Android && window.Android.receivedData) {
      // Call the receivedData function and capture the returned values
      const data = { callback: "handleCallback" };
      window.Android.receivedData(JSON.stringify(data));

      // Check if the result is an array with two values
    }
  };

  function onImageLoad(e) {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  }
  //console.log("onlyseeimage", onlySeeImage);
  function onDownloadCropClick() {
    // setImgSrc("")
    handleCheck();
    setDisplay(false);

    // console.log("canvas", imgSrc);

    if (!previewCanvasRef.current) {
      if (imgSrc) {
        setImageData(imgSrc);
      }
      throw new Error("Crop canvas does not exist");
    }

    previewCanvasRef.current.toBlob((blob) => {
      console.log(blob);

      if (!blob) {
        throw new Error("Failed to create blob");
      }
      if (blobUrlRef.current) {
        URL.revokeObjectURL(blobUrlRef.current);
      }
      const reader = new FileReader();
      reader.onloadend = () => {
        const imageDataUri = reader.result;
        console.log("imagedata", imageDataUri);
        setImageData(imageDataUri);
      };
      reader.readAsDataURL(blob);
      // blobUrlRef.current = URL.createObjectURL(blob)

      // hiddenAnchorRef.current.href = blobUrlRef.current
      // hiddenAnchorRef.current.click()
    });
  }
  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate
        );
      }
    },
    100,
    [completedCrop, scale, rotate]
  );

  const handleChange = (e) => {
    const { name, value } = e.target
    setLanguage(value)
  }

  return (
    <>
      {dispaly && (
        <div className="App">
          <div className="choose">
            {showCase && (
              <>
              <FormControl style={{position:"absolute",top:"20px",right:"0"}} variant="standard" sx={{ m: 1, minWidth: 120 }}>
        <Select
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard"
          value={language}
          onChange={handleChange}
          label="Age"
        >
        <MenuItem value="None">
            <em>None</em>
          </MenuItem>
           <MenuItem  value="eng"><div style={{display:"flex",justifyContent:"space-around",alignItems:"center",gap:"5px"}}><img style={{marginBottom:"4px"}} src={english} alt="english"/><span>English</span></div></MenuItem>
            <MenuItem value="ara"><div style={{display:"flex",justifyContent:"space-around",alignItems:"center",gap:"5px"}}><img style={{marginBottom:"4px"}} src={arab} alt="english"/><span>Arabic</span></div></MenuItem>
            <MenuItem value="fra"><div style={{display:"flex",justifyContent:"space-around",alignItems:"center",gap:"5px"}}><img style={{marginBottom:"4px"}} src={french} alt="english"/><span>French</span></div></MenuItem>
            <MenuItem value="spa"><div style={{display:"flex",justifyContent:"space-around",alignItems:"center",gap:"5px"}}><img style={{marginBottom:"4px"}} src={span} alt="english"/><span>Spanish</span></div></MenuItem>
            <MenuItem value="por"><div style={{display:"flex",justifyContent:"space-around",alignItems:"center",gap:"5px"}}><img style={{marginBottom:"4px"}} src={port} alt="english"/><span>Portuguese</span></div></MenuItem>
            <MenuItem value="por"><div style={{display:"flex",justifyContent:"space-around",alignItems:"center",gap:"5px"}}><img style={{marginBottom:"4px"}} src={russ} alt="english"/><span>Russian</span></div></MenuItem>

        </Select>
      </FormControl>
              <p style={{marginBottom:"50px"}} className="headingNext">
                You can add/update products in your shop by scanning products printed on a receipt. Click on the button below to start
              </p>
             
              {/* <Box style={{ marginTop: '10px' }} sx={{ minWidth: 180 }}>

        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">
            Select
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
           value={language}
          //   name={index}
            label="Age"
             onChange={handleChange}
          >
            <MenuItem  value="eng">English</MenuItem>
            <MenuItem value="ara">Arabic</MenuItem>
            <MenuItem value="fra">French</MenuItem>
            <MenuItem value="spa">Spanish</MenuItem>
            <MenuItem value="por">Portuguese</MenuItem>
            <MenuItem value="rus">Russian</MenuItem>


          </Select>
        </FormControl>
      </Box> */}
                <input
                  type="file"
                  accept="image/*"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  onChange={onSelectFile}
                />
                <div onClick={handleButtonClick} >
                 
                    <img style={{ width: "100%" }} src={image} alt="image" />{" "}
                </div>
               
                {/* <span style={{fontWeight:"normal"}} className="heading">Scan products</span> */}
              </>
            )}
          </div>
          {!!imgSrc && (
            <>
              {onlySeeImage ? (
                <>
                  <div
                    style={{
                      marginTop: "10px",
                      // display: "flex",
                      // flexDirection: "column",
                    }}
                  >
                    <div
                      onClick={() => {
                        setOnlySeeImage("");
                        //setHandleToggle(true);

                        //handleToggleAspectClick();
                        // setCrop(undefined);
                      }}
                      className="cropImage"
                    >
                      <i aria-hidden="true">
                        <img
                          style={{ width: "100%" }}
                          src={cropImage}
                          alt="image"
                        />
                      </i>
                      {/* <span>Crop</span> */}
                    </div>
                    <img
                      style={{
                        maxHeight: "75vh",
                        maxWidth: "97%",
                        padding: "5px",
                      }}
                      src={onlySeeImage}
                    />
                  </div>
                </>
              ) : (
                <>
                  <div
                    style={{
                      fontSize: "16px",
                      marginBottom: "4px",
                      marginTop: "4px",
                    }}
                    className="heading"
                  >
                    Tap to select or continue to use full image
                  </div>
                  <ReactCrop
                    //  style={{ width: "100%", height: "auto" }}
                    crop={crop}
                    onChange={(_, percentCrop) => setCrop(percentCrop)}
                    onComplete={(c) => setCompletedCrop(c)}
                    aspect={aspect}
                    keepSelection={true}
                    // minWidth={400}
                  >
                    <img
                      ref={imgRef}
                      alt="Crop me"
                      src={imgSrc}
                      style={{
                        transform: `scale(${scale}) rotate(${rotate}deg)`,
                      }}
                      onLoad={onImageLoad}
                    />
                    {/* <div>Touch to Crop</div> */}
                  </ReactCrop>
                </>
              )}
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "20px",
                  marginTop: "10px",
                }}
              >
                <button
                  className="buttons"
                  style={{
                    background: "none",
                    border: "1px solid #333",
                    color: "#333",
                  }}
                  onClick={handleResetStates}
                >
                  Discard
                </button>
                <button className="buttons" onClick={onDownloadCropClick}>
                  Continue
                </button>

                <a
                  href="#hidden"
                  ref={hiddenAnchorRef}
                  download
                  style={{
                    position: "absolute",
                    top: "-200vh",
                    visibility: "hidden",
                  }}
                >
                  Hidden download
                </a>
              </div>
            </>
          )}
          {!!completedCrop && (
            <>
              <div style={{ display: "none" }}>
                <canvas
                  ref={previewCanvasRef}
                  style={{
                    border: "1px solid black",
                    objectFit: "contain",
                    width: completedCrop.width,
                    height: completedCrop.height,
                  }}
                />
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
}
