import { Box, Modal } from "@mui/material";
import React from "react";

function ReusableModal({open,handleClose,children,style,cross}) {
  return <div>
  
  <Modal 
  open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
       <Box sx={style}>
       {/* {cross?"": <div onClick={()=>handleClose()} style={{position:"absolute",
       top:"20px",right:"33px"}}>X</div>
       } */}
{children}
</Box>
  </Modal>
  
  
  
  </div>;
}

export default ReusableModal;
