import './App.css';
import DetectDevice from './DetectDevice';

function App() {
  console.log = () => {};
   console.debug = () => {};
   console.info = () => {};
   console.warn= () => {};
 
  
  return (
    <div className="App">
      <DetectDevice/>
    </div>
  );
}

export default App;
