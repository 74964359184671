import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import  close from "./images/svg/close.svg"
// import Slide from "@mui/material/Slide";

const style = {
  position: "absolute",
  top: "auto",
  left: "50%",
  transform: "translate(-50%, -50%)",
  borderRadius: "20px",
  width: "100%",
  bgcolor: "white",
  color: "black",
  boxShadow: 24,
};

function ModalEdit({
  open,
  setOpen,
  handleEditTable,
  setFinalArray,
  finalArray,
  firstcolumn,
}) {
  const [indexNum, setIndexNum] = useState();
  console.log("firstcolumn", firstcolumn);
  const handleChange = (e) => {
    const { name, value } = e.target;
    const updatedIndexNum = [...indexNum];
    updatedIndexNum[name] = value;
    setIndexNum(updatedIndexNum);
  };
  //console.log("firstColumnss", indexNum);
  const handleSubmit = () => {
    const newArray = [...finalArray];
    newArray[firstcolumn.index] = indexNum;
    setFinalArray(newArray);
    setOpen(false);
  };
  const handleClose = () => setOpen(false);
  useEffect(() => {
    // handleEditTable(firstcolumn.a, firstcolumn.b);
    // const newArray = [...finalArray];
    setIndexNum(finalArray[firstcolumn.index]);
    // Step 2: Update the value in the copied array
    if (Object.keys(firstcolumn).length > 0) {
      console.log("firstcolumn", firstcolumn);
      //  alert(firstcolumn);
      setOpen(true);
    }
    //newArray[firstcolumn.index] = ["ahmad", "khalil", "wardah"];
    // setFinalArray(newArray);
    console.log("firstColumn", indexNum);
  }, [firstcolumn]);

  return (
    // <Slide direction="up" in={open} mountOnEnter unmountOnExit>
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          ...style,
          bottom: open ? "0%" : "-100%", // Slide from bottom to top
          transition: "top 0.5s ease", // Increase transition duration to 0.5s
          position: "fixed",
          borderBottomRightRadius:"0px",
          borderBottomLeftRadius:"0px",
          overflow:"auto",
          left: "50%",
          transform: "translateX(-50%)",
          borderRadius: "10px",
        }}
      >
        <div style={{padding:"20px"}}>
          <div
          style={{marginBottom:"10px",textAlign:"center"}}
            onClick={() => handleClose()}
          >
            <img alt="close" src={close}/>
          </div>
          {firstcolumn?.index === 0 ? (
            <Typography color="#474747" fontWeight="700" textAlign="center" id="modal-modal-title" variant="h6" component="h2">
              Edit the Title
            </Typography>
          ) : (
            <Typography color="#474747" fontWeight="700" textAlign="center" id="modal-modal-title" variant="h6" component="h2">
              Edit the Row
            </Typography>
          )}
          <div style={{width:firstcolumn?.index === 0?"100%":"100%"}} className="form">
            <div
              style={{ display: "flex", flexDirection: "column", gap: "10px",width:"100%" }}
              class="ui input"
            >
              {/* <label>Total Products: </label> */}
              {firstcolumn?.index === 0 ? (
                <div>
                  {indexNum?.map((val, index) =>
                    firstcolumn?.cellIndex === index ? (
                      <Box style={{ marginTop: "10px" }} sx={{ minWidth: 180 }}>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">
                            Select
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={val}
                            name={index}
                            label="Age"
                            onChange={handleChange}
                          >
                            <MenuItem value="sku">Sku</MenuItem>
                            <MenuItem value="price">Price</MenuItem>
                            <MenuItem value="name">Name</MenuItem>
                          </Select>
                        </FormControl>
                      </Box>
                    ) : null
                  )}
                </div>
              ) : (
                <>
                  {indexNum?.map((val, index) => (
                    <>
                      {typeof val != "object" && (
                        <input
                          name={index}
                          type="text"
                          value={val}
                          key={index} // Key is important when rendering a list of elements
                          // placeholder="Total Products "
                          onChange={handleChange}
                        />
                      )}
                    </>
                  ))}
                </>
              )}
            </div>
          </div>
          <div style={{ marginTop: "17px", marginBottom: "17px" }}>
            <button
              style={{ width: "100%", cursor: "pointer" }}
              className="buttons"
              onClick={handleSubmit}
            >
              Save
            </button>{" "}
          </div>
        </div>
      </Box>
    </Modal>
    // </Slide>
  );
}

export default ModalEdit;
