import * as React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

 function CircularProgressWithLabel() {
  return (
    <Box sx={{
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background:"rgba(256 , 256,256,0.8)"
      }}>
    <CircularProgress />
  </Box>
  );
}

export default CircularProgressWithLabel;