import React, { useState, useEffect } from "react";
import ReusableModal from "./ReusableModal";
import { dataSendToAndroid } from "./DataSendAndroidIos";
import closeModal from ".//images/svg/closeModal.svg"
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  borderRadius: "20px",
  width: "98%",
  maxHeight: "85vh",
  overflow: "hidden",
  bgcolor: "white",
  color: "black",
  boxShadow: 24,
  p: 4,
};

const UpdateModal = ({
  openUpdateModal,
  setOpenUpdateModal,
  updateData,
  suggestedArray,
  handleSendApiSingleSuggested,
  setSelectedIndices,
  count,
}) => {
  console.log("updateData", updateData?.index);
  console.log("suggestedArrayss", suggestedArray);

  const [nameFilter, setNameFilter] = useState("");
  const [selectObj, setSelectObj] = useState();
  const [filteredPersons, setFilteredPersons] = useState();
  const handleClose = () => setOpenUpdateModal(false);
  useEffect(() => {
    setFilteredPersons(updateData?.arr);
  }, [updateData?.arr]);

  const handleRadioChange = (obj) => {
    setSelectObj(obj);
    console.log("handleRadioChange", obj);
  };

  const handleCreate = () => {
    console.log("selectObj", selectObj);
    let demy = { suggested: selectObj, newProduct: updateData?.obj };
    dataSendToAndroid(demy);
    if (suggestedArray.length > updateData?.index + 1) {
      const nextIndex = updateData?.index + 1;
      setSelectedIndices((prevSelectedIndices) => [
        ...prevSelectedIndices,
        nextIndex,
      ]);
      const nextSuggestedItem = suggestedArray[nextIndex];
      console.log("nextSuggestedItem", nextSuggestedItem);
      handleSendApiSingleSuggested(nextSuggestedItem, nextIndex, true);
      setSelectObj(null); // Reset radio button selection
    }
  };

  const handleChange = (event) => {
    const inputName = event.target.value;
    setNameFilter(inputName);

    const filteredArray = updateData?.arr.filter((person) =>
      person?.product.name.toLowerCase().includes(inputName.toLowerCase())
    );
    setFilteredPersons(filteredArray);
  };
console.log("filteredPersons",filteredPersons)
  const highlightText = (text, highlight) => {
    const parts = text.split(new RegExp(`(${highlight})`, "gi"));
    return (
      <span>
        {parts.map((part, index) => (
          <span
            key={index}
            style={
              part.toLowerCase() === highlight.toLowerCase()
                ? { color: "#E4126B" }
                : {}
            }
          >
            {part}
          </span>
        ))}
      </span>
    );
  };
  return (
    <div>
      <ReusableModal
        style={style}
        open={openUpdateModal}
        handleClose={handleClose}
      >
        <div style={{ textAlign: "center" }}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <p className="headingNextNewUi">Suggested Product <span
            className="row-price" style={{fontWeight:"700"}}
            >
              ({count} - {suggestedArray.length})
            </span>{" "} </p>
            <p onClick={handleClose} className="headingNextNewUi" >
            <img alt="close" src={closeModal}/>

            </p>
          </div>
          <div style={{ marginTop: "15px" }} className="ui input">
            <input
              style={{ height: "52px" }}
              type="text"
              id="nameFilter"
              placeholder="Enter name"
              value={nameFilter}
              onChange={handleChange}
            />
          </div>
          <div style={{ height: "60px",padding:"15px",marginTop:"15px" }} className="row-new-ui">
          <div style={{display:"flex",flexDirection:"column",gap:"6px",alignItems:"flex-start"}}>
         <div className="row-sku">Name</div>
         <div className="row-name">{updateData?.obj?.name}</div>   
         </div>
         <div style={{display:"flex",flexDirection:"column",gap:"6px",alignItems:"flex-start"}}>
         <div className="row-sku">SKU:{updateData?.obj?.sku}</div>
         <div className="row-price">Price:{updateData?.obj?.price}</div>   
         </div>
          </div>
          
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop:"10px"
            }}
          >
            
            
            <button
                     style={{borderRadius:"5px",height:"39px"}}   className="newUiButtons"

              onClick={() => dataSendToAndroid(updateData?.obj)}
              aria-hidden="true"
            >
              New Product
            </button>
            {selectObj && (
              <button
              style={{borderRadius:"5px",height:"39px"}} 
                         className="newUiButtons"

                onClick={handleCreate}
                aria-hidden="true"
              >
                Create
              </button>
            )}
          </div>
          {filteredPersons?.length === 0 ? (
            <p className="row-sku">No data found</p>
          ) : (
            <>
              <div  style={{
                      width: "100%",
                      maxHeight: "35vh",
                      overflow: "auto",
                    }}>
               
                  {filteredPersons?.map((val, index) => (
                    <div             style={{height:"60px"}}               className="row-new-ui">
                    <div className="row-all" key={index}>
                     
                      <div className="row-sku"> {val?.product?.sku}</div>
                      <div className="row-name">{highlightText(val?.product?.name, nameFilter)}</div>

                      <div className="row-price">Price:{val?.price}</div>
                    </div>
                     <div class="form-control"
                            style={{ paddingRight: "30px" }}>
                        <input
                          type="radio"
                          style={{height:"20px"}}
                          name="selectedSuggestion"
                          checked={selectObj === val} // Check if this item is selected
                          value={val}
                          onChange={() => handleRadioChange(val)}
                        />
                      </div>
                    </div>
                  ))}
              </div>
            </>
          )}
        </div>
      </ReusableModal>
    </div>
  );
};

export default UpdateModal;
