export const dataSendToAndroid=(data)=>{
    console.log("datattaandroid",JSON.stringify(data))
    
        // Assuming 'Android' is the name of the JavaScript interface in the WebView for Android
        if (window.Android) {
          window.Android.updateData(JSON.stringify(data));
      }
    
      // Assuming 'iOS' is the name of the JavaScript interface in the WebView for iOS
      if (window.webkit && window.webkit.messageHandlers.updateData) {
        window.webkit.messageHandlers.updateData.postMessage(JSON.stringify(data));
    }
    
    
}